import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home.page";
import Contact from './pages/Contact.page';
import Services from './pages/Services.page';
import Privacy from './pages/Privacy.page';
import Terms from './pages/Terms.page';
import About from './pages/About.page';
import Giftcards from './pages/Giftcards.page';
import MarketingUpload from './pages/MarketingUpload.page';

import './App.scss';

const routesData = [
  { path: "/", component: Home },
  { path: "/contact", component: Contact },
  { path: "/services", component: Services },
  { path: "/privacy", component: Privacy },
  { path: "/terms", component: Terms },
  { path: "/about", component: About },
  { path: "/giftcards", component: Giftcards },
  { path: "/marketing", component: MarketingUpload }
];

function App() {
  return (
    <Router>
      <Routes>
        {routesData.map(route => (
          <Route key={route.path} path={route.path} element={<route.component />} />
        ))}
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;