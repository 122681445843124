import React, { useState } from 'react';
import { storage, firestore } from '../firebase';

import Header from "../components/Header";
import Footer from "../components/Footer";
import { ref } from 'firebase/storage';

const MarketingUpload: React.FC = () => {

    // const [file, setFile] = useState(null);
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');

    // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.files) {
    //         setFile(e.target.files[0]);
    //     }
    // };

    // const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {

    // }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!file || !startDate || !endDate) {
    //         alert("Please fill in all fields.");
    //         return;
    //     }

    //     const storageRef = ref(storage, 'marketing/');
    //     const fileRef = storageRef.child(`marketing_images/${file.name}`);

    //     // Upload the image
    //     await fileRef.put(file);

    //     // Get the download URL
    //     const downloadURL = await fileRef.getDownloadURL();

    //     // Save metadata to Firestore
    //     await firestore.collection('marketing_images').add({
    //         url: downloadURL,
    //         startDate: new Date(startDate).toISOString(),
    //         endDate: new Date(endDate).toISOString()
    //     });

    //     alert('Image uploaded successfully!');
    //     setFile(null);
    //     setStartDate('');
    //     setEndDate('');
    // };

    return (
        <main>
            {/* <div className="header-gap"></div>
            <Header solidHeader={true} />
            <h1 className='text-center'>Marketing Upload</h1>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
                <button type="submit">Upload</button>
            </form>
            <Footer /> */}
        </main>
    );
}

export default MarketingUpload;