import Awards from "../components/Awards";
import Gallery from "../components/Gallery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroImage from "../components/HeroImage";
// import Testimonials from "../components/Testimonials";

import './../theme/comp/home.scss'
import './../theme/ui/text.scss'
import './../theme/ui/button.scss'
import BookTwoPopover from "../popovers/Book-two.popover";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MarketingPopover from "../popovers/marketing.popover";

const Home: React.FC = () => {

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [mPopoverOpen, setMPopoverOpen] = useState(false);
    console.log('Trendzone Barbershop V5.0');

    useEffect(() => {
        setMPopoverOpen(true);

        const timer = setTimeout(() => {
            setMPopoverOpen(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <main>
            <Header />
            <p className="grid hero-text">Classic Cuts,<br />Modern Styles.</p>
            <HeroImage />
            <Awards />
            <div className="margin-bottom-96">
                <div className="grid text-center">
                    <p className="headline-text">Trendzone Barbershop</p><br />
                    <p className="description-text">Welcome to our classic barbershop with a modern twist, located in South Surrey. We offer a full range of grooming services for men, making your satisfaction our top priority. We promise you'll leave our shop looking fresh and feeling happy. <br /><br />As soon as you walk through our doors, you'll be welcomed with a complimentary drink or coffee, ensuring you feel right at home. Our barbers, each with unique passions and diverse experience, are ready to match any style you desire.</p>
                </div>
            </div>

            <Gallery />
            <div className="margin-bottom-96"></div>

            <div className="grid text-center margin-top-48">
                <p className="headline-text">Our establishment is fully licensed to serve a selection of fine alcoholic beverages.</p>
                <p className="description-text">You can choose to purchase Whiskey, Scotch, Rum, Vodka, Tequila, or Beer, or enjoy a complimentary non-alcoholic beverage such as Juice, Coffee, Soda, or Water from us.</p>
                <h3>See our <Link to="/services"><span>Services</span></Link></h3>
            </div>
            <div className="margin-bottom-96"></div>

            <div className="flex-std">
                <button className="btn-std btn-large" onClick={() => setPopoverOpen(!popoverOpen)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" viewBox="0 0 512 512"><circle cx="104" cy="152" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><circle cx="104" cy="360" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M157 175l-11 15 37 15s3.46-6.42 7-10z" fill="none" stroke="currentColor" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="32" /><path d="M154.17 334.43L460 162c-2.5-6.7-28-12-64-4-29.12 6.47-121.16 29.05-159.16 56.05C205.85 236.06 227 272 192 298c-25.61 19-44.43 22.82-44.43 22.82zM344.47 278.24L295 306.67c14.23 6.74 65.54 33.27 117 36.33 14.92.89 30 .39 39-6z" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" /><circle cx="256" cy="240" r="32" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /></svg>
                    Book Now
                </button>
            </div>

            <MarketingPopover show={mPopoverOpen} onClose={() => setMPopoverOpen(false)} />

            <BookTwoPopover show={popoverOpen} onClose={() => setPopoverOpen(false)} />

            {/* <Testimonials />
            <div className="margin-bottom-96"></div> */}
            <Footer />
        </main>
    );
}

export default Home;