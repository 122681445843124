import { useEffect, useRef } from "react";
import './../theme/ui/marketing-popover.scss';

interface MarketingPopoverProps {
    show: boolean;
    onClose: () => void;
    imageUrl?: string;
}

const MarketingPopover: React.FC<MarketingPopoverProps> = ({ show, onClose }) => {

    const imageUrl = process.env.PUBLIC_URL + '/images/mpop.png';
    const containerRef = useRef<HTMLDivElement>(null);

    const expirationDate = new Date('2025-01-01T00:00:00Z');
    const isExpired = new Date() >= expirationDate;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (show && !isExpired) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, onClose, isExpired]);

    if (!show || isExpired) return null;

    return (
        <div className="marketing-popover-overlay-dark">
            <div className='marketing-popover-container-center' ref={containerRef}>
                <img className="marketing-popover-image" src={imageUrl} alt="Marketing Content" />
                <p className="marketing-popover-close" onClick={onClose}>CLOSE</p>
            </div>
        </div>
    );
}

export default MarketingPopover;